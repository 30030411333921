export const nfts = [
    {
        "src": "./assets/luna.jpeg",
        "alt": "Luna Warrior Princess",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098937887478513665"
    },
    {
        "src": "./assets/UST.jpeg",
        "alt": "UST the stable",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098938986990141441"
    },
    {
        "src": "./assets/chai.jpeg",
        "alt": "Chai the dealmaker",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098940086501769217"
    },
    {
        "src": "./assets/mirror.jpeg",
        "alt": "Shiny Mirror",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098941186013396993"
    },
    {
        "src": "./assets/anchor.jpeg",
        "alt": "Unbreakable Anchor",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098942285525024769"
    },
    {
        "src": "./assets/pylon.jpeg",
        "alt": "Paymaster Pylon",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098943385036652545"
    },
    {
        "src": "./assets/orion.jpeg",
        "alt": "Orion the hunter",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098944484548280321"
    },
    {
        "src": "./assets/loop.jpeg",
        "alt": "Loop the exchanger",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098945584059908097"
    },
    {
        "src": "./assets/spar.jpeg",
        "alt": "Spar the manager",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098946683571535873"
    },
    {
        "src": "./assets/talis.jpeg",
        "alt": "Artist Talis",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098947783083163649"
    },
    {
        "src": "./assets/nebula.jpeg",
        "alt": "Cluster creator Nebula",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098948882594791425"
    },
    {
        "src": "./assets/mars.jpeg",
        "alt": "Mars the lender",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098949982106419201"
    },
    {
        "src": "./assets/loterra.jpeg",
        "alt": "Loterra the Fortune Maker",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098951081618046977"
    },
    {
        "src": "./assets/angel.jpeg",
        "alt": "Angel the philanthropist",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098952181129674753"
    },
    {
        "src": "./assets/starterra.jpeg",
        "alt": "StarTerra, the wingman",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098953280641302529"
    },
    {
        "src": "./assets/terraswap.jpeg",
        "alt": "TerraSwap, the barter",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098954380152930305"
    },
    {
        "src": "./assets/tsunami.jpeg",
        "alt": "Tsunami, the secretkeeper",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098955479664558081"
    },
    {
        "src": "./assets/whitewhale.jpeg",
        "alt": "Whitewhale, keeper of the peg",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098956579176185857"
    },
    {
        "src": "./assets/pisco.jpeg",
        "alt": "Pisco high $LUNA",
        "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/39628116462589717377396691736049422560445119976229933443305098957678687813633"
    }
]