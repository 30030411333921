import './App.css';
import { nfts } from './assets';

function App() {
  return (
    <div className="lunaPunks">
      <header className="header">
        Legends of Luna
      </header>
      <div className="headerText">
          Legends of Luna is the series of fan created characters inspired by amazing applications being developed in Terra ecosystem. 
          These applications are personified and sketched as a team around warrior princess Luna. The sketches are minted as NFTs on OpenSea.
      </div>
      <div className="gridContainer">
        {nfts.map((data, key) => {
          return (
            <div className="gridItem">
              <a href={data.href}>
                <img src={data.src} alt={data.alt} />
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default App;
